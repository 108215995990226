<template>
  <div class="cups-menu-container">
    <v-navigation-drawer temporary touchless right
                         fixed enable-resize-watcher disable-route-watcher
                         v-model="navigation.drawer" app>
      <v-list class="app-menu-wrapper">
        <p style="text-align: right; margin-right: 15px;">
          <a class="close-menu" @click.stop="navigation.drawer = !navigation.drawer">
            <v-icon large>{{icons.close}}</v-icon>
          </a>
        </p>
        <v-list-item>
          <v-list-item-content @click="goToLanding()">
            <v-list-item-title>
              {{ $t("menu.home") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="competition !== null">
          <v-list-item-content @click="goToCup(competition.value)">
            <v-list-item-title>
              {{ $t(competition.label) }} {{ $t(competition.location) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar flat
               :class="[{'scrolled' : navigation.scrolled}, {'whitened': !navigation.scrolled}]">
      <v-toolbar-title v-if="navigation.scrolled">
        <img alt="EBCUP" v-if="competition === null"
             src="https://www.ebikecup.com/images/EBCUP_LOGO.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-BURGENLAND'"
             src="https://www.ebikecup.com/images/cups/burgenland/EBCUP_Logo_Burgenland_Dunkel_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-BOZEN'"
             src="https://www.ebikecup.com/images/cups/bozen/EBCUP_Logo_Bozen_Dunkel_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-ENTDECKERVIERTEL'"
             src="https://www.ebikecup.com/images/cups/entdeckerviertel/EBCUP_Logo_Entdeckerviertel_Dunkel_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-OSTTIROL'"
             src="https://www.ebikecup.com/images/cups/osttirol/EBCUP_Logo_Osttirol_Dunkel_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-MUERZTAL'"
             src="https://www.ebikecup.com/images/cups/muerztal/EBCUP_Logo_Muerztal_Dunkel_01-01.png">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-HOHE-SALVE'"
             src="https://ebikecup.com/images/cups/hohesalve/EBCUP_Logo_Hohe_Salve_Dunkel_Licence_01-01.png">
      </v-toolbar-title>
      <v-toolbar-title v-else>
        <img alt="EBCUP" v-if="competition === null"
             src="https://www.ebikecup.com/images/EBCUP_LOGO.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-BURGENLAND'"
             src="https://www.ebikecup.com/images/cups/burgenland/EBCUP_Logo_Burgenland_Hell_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-BOZEN'"
             src="https://www.ebikecup.com/images/cups/bozen/EBCUP_Logo_Bozen_Hell_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-ENTDECKERVIERTEL'"
             src="https://www.ebikecup.com/images/cups/entdeckerviertel/EBCUP_Logo_Entdeckerviertel_Hell_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-OSTTIROL'"
             src="https://www.ebikecup.com/images/cups/osttirol/EBCUP_Logo_Osttirol_Hell_01-01.svg">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-HOHE-SALVE'"
             src="https://ebikecup.com/images/cups/hohesalve/EBCUP_Logo_Hohe_Salve_Hell_Licence_01-01.png">
        <img alt="EBCUP" v-else-if="competition.value === 'E-BIKE-CUP-MUERZTAL'"
             src="https://www.ebikecup.com/images/cups/muerztal/EBCUP_Logo_Muerztal_Hell_01-01.png">
      </v-toolbar-title>
      <v-spacer/>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndDown">
        <v-btn icon class="menu-icon"
               @click.stop="navigation.drawer = !navigation.drawer" :ripple="false">
          <v-icon large>{{icons.menu}}</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="justify-center" v-else>
        <v-spacer/>
        <v-btn outlined
               @click="goToLanding()">
          {{ $t("menu.home") }}
        </v-btn>
        <v-btn outlined v-if="competition !== null"
               @click="goToCup(competition.value)">
          {{ $t(competition.label) }} {{ $t(competition.location) }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
  import { mdiClose, mdiMenu } from '@mdi/js'
  import i18next from 'i18next'

  export default {
    name: 'register-menu',
    props: {
      competition: undefined
    },
    data () {
      return {
        icons: {
          menu: mdiMenu,
          close: mdiClose,
        },
        fab: false,
        navigation: {
          scrolled: false,
          drawer: false,
        },
        langs: ['DE', 'EN'],
        lang: 'DE',
        listener: undefined
      };
    },
    watch: {
      lang(language) {
        this.$store.dispatch('setLanguage', language);
        this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
        i18next.changeLanguage(this.$store.getters.getLanguage);
      },
      $route(to, from) {
        if (to.name !== from.name) {
          this.closeMenu();
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener);
    },
    mounted() {
      window.addEventListener('scroll', this.scrollListener);
      this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
      i18next.changeLanguage(this.$store.getters.getLanguage);
      this.lang = this.$store.getters.getLanguage;
    },
    methods: {
      goToLanding() {
        this.closeMenu();
        this.$router.push({ name: 'home' });
      },
      goToCup(competition) {
        this.closeMenu();
        if (competition === 'E-BIKE-CUP-BOZEN') {
          this.$router.push({ name: 'bozen' });
        } else if (competition === 'E-BIKE-CUP-HOHE-SALVE') {
          this.$router.push({ name: 'hohesalve' });
        } else if (competition === 'E-BIKE-CUP-BURGENLAND') {
          this.$router.push({ name: 'burgenland' });
        } else if (competition === 'E-BIKE-CUP-ENTDECKERVIERTEL') {
          this.$router.push({ name: 'entdeckerviertel' });
        } else if (competition === 'E-BIKE-CUP-MUERZTAL') {
          this.$router.push({ name: 'muerztal' });
        }
      },
      scrollListener() {
        if (!this.isIE11()) {
          this.navigation.scrolled = window.scrollY > 50;
        } else {
          this.navigation.scrolled = window.pageYOffset > 50;
        }
      },
      closeMenu(selector) {
        this.navigation.drawer = false;
        if (selector !== undefined && selector !== null) {
          this.globalEvents.$emit('goToElementCups', selector);
        }
      },
      isIE11() {
        return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
      },
      getLanguage() {
        return this.$store.getters.getLanguage;
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .cups-menu-container {
    .v-toolbar {
      background-color: transparent;
      box-shadow: unset;
      position: fixed;
      width: 100%;
      z-index: 100;

      /* IE11 */
      @media all and (-ms-high-contrast:none)
      {
        box-shadow: none;
      }

      .v-toolbar__content {
        .v-toolbar__title {
          img {
            width: auto;
            //height: 100px;
            //top: 25px;
            //left: 30px;
            height: 70px;
            top: 5px;
            left: 20px;
            position: absolute;
            cursor: pointer;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
          }
        }
        .v-toolbar__items {
          @include MQ(XL) {
            width: 100%;
          }
          @include MQ(L) {
            width: 100%;
          }
          .v-btn {
            border: none;
            text-transform: none;
            color: white;
            text-indent: 0;
            .v-btn__content {
              font-family: $default-font;
              font-weight: 600;
            }
          }
          .v-input {
            max-width: 100px;
            .v-input__control {
              height: 100%;
              max-width: 100px;
              .v-input__slot {
                max-width: 85px;
                background-color: transparent !important;
                box-shadow: unset !important;
                height: 100%;
                margin-bottom: 0;
                /* IE11 */
                @media all and (-ms-high-contrast:none)
                {
                  box-shadow: none !important;
                }
                .v-select__selections {
                  color: white;
                  font-weight: 500;
                  /* IE11 */
                  @media all and (-ms-high-contrast:none)
                  {
                    height: 31px;
                  }
                  input {
                    width: 5px;
                  }
                }
                .v-input__append-inner {
                  .v-input__icon {
                    svg {
                      color: white !important;
                    }
                  }
                }
              }
              .v-text-field__details {
                display: none;
              }
            }
          }
        }
      }

      .menu-icon {
        .v-icon {
          color: white;
        }
      }

      &.whitened {
        .v-toolbar__content {
          .v-toolbar__items {
            .v-btn {
              color: white;
            }
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: white;
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: white !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.scrolled {
        width: unset;
        background-color: white;
        z-index: 100;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
        0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
        .v-toolbar__items {
          width: unset;
        }
        .v-toolbar__content {
          width: unset;
          img {
            height: 55px;
            top: 5px;
            left: 10px;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
            @include MQ(XS) {
              top: 0;
            }
            @include MQ(S) {
              top: 0;
            }
          }
          .v-toolbar__items {
            .v-btn {
              color: $primary-text;
            }
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: $primary-text;
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: $primary-text !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .menu-icon {
          .v-icon {
            color: $primary-text;
          }
        }
      }
    }

    .v-list {
      .v-list-item {
        .v-list-item__content {
          text-align: left;
          color: $primary-text;
          font-family: $default-font;
        }
      }
    }

    .v-navigation-drawer {
      color: $primary-text;
      z-index: 1001;
      text-align: left;
      font-family: $default-font;

      .close-menu {
        svg {
          fill: $primary-text;
        }
      }

      .v-input {
        .v-input__control {
          .v-input__slot {
            padding: 0;
            box-shadow: none;
            min-height: 30px;
          }
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
</style>
