export default {
  data: () => ({
    competitions: [
      {label: 'home.about.events.cup', location: 'home.about.events.hohesalve.name', date: 'home.about.events.hohesalve.date', value: 'E-BIKE-CUP-HOHE-SALVE', disabled: true},
      {label: 'home.about.events.cup', location: 'home.about.events.burgenland.name', date: 'home.about.events.burgenland.date', value: 'E-BIKE-CUP-BURGENLAND', disabled: true},
      {label: 'home.about.events.cup', location: 'home.about.events.entdeckerviertel.name', date: 'home.about.events.entdeckerviertel.date', value: 'E-BIKE-CUP-ENTDECKERVIERTEL', disabled: true},
      {label: 'home.about.events.cup', location: 'home.about.events.muerztal.name', date: 'home.about.events.muerztal.date', value: 'E-BIKE-CUP-MUERZTAL', disabled: true},
      {label: 'home.about.events.cup', location: 'home.about.events.bozen.name', date: 'home.about.events.bozen.date', value: 'E-BIKE-CUP-BOZEN', disabled: true}
    ]
  }),
  methods: {
    isCompetitionDisabled (competition) {
      for (let i = 0; i < this.competitions.length; i += 1) {
        if (competition === this.competitions[i].value) {
          return this.competitions[i].disabled;
        }
      }
      return false;
    }
  }
};
