<template>
  <div class="register-container">
    <Menu :competition="register.competition"/>
    <div class="header">
      <div class="mobile-wrapper">
        <div class="header-background"
             :style="{backgroundImage: 'url(https://www.ebikecup.com/images/register/EBCUPS_Headerbild_Anmeldeformular_01.jpg)'}">
          <div class="header-title-container">
            <h1>{{ $t("register.title") }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="register-content-wrapper">
      <v-container>
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
          <v-card>
            <v-card-title primary-title>
              <div class="deal-content">
                <h3 class="headline mb-3">{{ $t("register.included") }}:</h3>
                <ul>
                  <li>{{ $t("home.about.information.ul5") }}</li>
                  <li>{{ $t("home.about.information.ul6") }}</li>
                </ul>
                <h3 class="headline mt-3">{{ $t("register.notIncluded") }}:</h3>
                <v-row>
                  <v-col cols="12" sm="7" md="6">
                    <ul>
                      <li>
                        {{ $t("register.notIncludedServices.rent") }} <br>
                        <small>{{ $t("register.notIncludedServices.rentHint") }}</small>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
          <v-card>
            <v-card-title primary-title>
              <div class="deal-content">
                <v-form @submit="submit" v-model="register.valid"
                        ref="form" lazy-validation>
                  <p>{{ $t("register.chooseYourChallenge") }}</p>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" xl="5" class="pt-0">
                      <v-select
                        v-model="register.competition"
                        :items="competitions"
                        :label="$t('register.competition')"
                        persistent-hint
                        return-object :rules="[(v) => !!v || $t('required')]"
                        single-line required>
                        <template slot='selection' slot-scope='{ item }'>
                          {{$t(item.label)}} {{$t(item.location)}}
                          <span v-if="$vuetify.breakpoint.smAndUp" style="margin-left: 4px">- {{$t(item.date)}}</span>
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                          {{$t(item.label)}} {{$t(item.location)}}
                          <span v-if="$vuetify.breakpoint.smAndUp" style="margin-left: 4px"> - {{$t(item.date)}}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <p class="form-required-title">{{ $t("register.required") }}:</p>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" xl="5">
                      <v-text-field
                        :label="$i18n.t('register.firstName')"
                        v-model="register.firstName"
                        :rules="rules"
                        validate-on-blur required
                      />
                      <v-text-field
                        :label="$i18n.t('register.lastName')"
                        v-model="register.lastName"
                        :rules="rules"
                        validate-on-blur required
                      />
                      <v-text-field
                        label="Misc"
                        class="misc"
                        v-model="register.misc"
                        validate-on-blur
                      />
                      <p>{{ $t("register.sex") }}:</p>
                      <v-btn @click="selectSex('women')"
                             :class="[{'selected': isSexSelected('women')},
                       {'error': checkIfSexIsSelected()}]">
                        {{ $t("register.sexes.women") }}
                      </v-btn>
                      <v-btn @click="selectSex('men')"
                             :class="[{'selected': isSexSelected('men')},
                       {'error': checkIfSexIsSelected()}]">
                        {{ $t("register.sexes.men") }}
                      </v-btn>

                      <div class="birthday">
                        <v-text-field
                          v-model="register.birthYear"
                          :label="$t('register.birthday')"
                          :prepend-icon="icons.calendar"
                          required :rules="yearRules"
                          type="number"
                          counter="4"
                          :placeholder="$t('YYYY')"
                        />
                      </div>

                      <v-text-field
                        :label="$i18n.t('register.email')"
                        v-model="register.email"
                        :rules="emailRules"
                        validate-on-blur required
                      />

                      <v-select
                        v-model="register.nationality"
                        :items="countries"
                        item-text="label"
                        item-value="value"
                        :label="$t('register.nationality')"
                        persistent-hint
                        return-object :rules="[(v) => !!v || $t('required')]"
                        single-line required
                      />

                      <p class="form-required-title" style="margin-top: 15px;">
                        {{ $t("register.notRequired") }}
                      </p>

                      <v-text-field
                        :label="$i18n.t('register.club')"
                        v-model="register.association"
                        validate-on-blur
                      />

                      <v-text-field
                        :label="$i18n.t('register.promotionCode')"
                        v-model="register.promotionCode"
                        validate-on-blur
                      />

                      <v-textarea
                        :label="$i18n.t('register.message')"
                        v-model="register.message"
                      />
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row>
                    <v-col cols="12">
                      <v-layout class="align-center">
                        <v-checkbox color="primary" class="shrink mr-2"
                                    style="margin-top: 8px;"
                                    v-model="register.rules" hide-details
                                    :rules="[v => !!v || $t('required')]"
                                    required>
                        </v-checkbox>
                        <span @click="selectRules()" style="cursor: pointer">
                          {{ $t('register.rules') }}
                        </span>
                        <a @click="downloadRules" style="margin-left: 10px;">
                          {{ $t('register.download') }}
                        </a>
                      </v-layout>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
                        {{ $t('register.button') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-container>
    </div>
    <v-dialog v-model="dialog.response" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card v-if="success !== undefined">
        <v-card-text>
          <v-row v-if="success">
            <v-col cols="12" class="pb-0 pt-0">
              <img v-lazyimg="'https://www.ebikecup.com/images/register/EBWM_Register_Success.jpg'">
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <h1>
                <span v-html="$t('register.success')"/>
              </h1>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <img v-lazyimg="'https://www.ebikecup.com/images/register/EBWM_Register_Error.jpg'">
            </v-col>
            <v-col cols="12">
              <h1>
                <span v-html="$t('register.error')"/>
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.response = false">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.registering" scrollable
              max-width="600px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card>
        <v-card-title>
          <h3>Fehler</h3>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-left">
              <v-alert outlined type="error" prominent border="left">
                <div v-html="$t('registeringError')"/>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-icon large color="primary" style="margin-top: -5px">{{icons.mail}}</v-icon>
            </v-col>
            <v-col cols="10" class="text-left">
              <a style="text-decoration: none;"
                 href="mailto:info@ebikecup.com" target="_blank">info@ebikecup.com</a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.registering = false">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" max-width="400px" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card>
        <v-card-text>
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-center" cols="12">
              <h3>{{$t('registering')}}</h3>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="snackbar.timeout"
      :bottom="snackbar.bottom"
      :color="snackbar.color"
      v-model="snackbar.value">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon
               @click="snackbar.value = false">
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  /* eslint-disable no-console,no-undef */
  import {mdiCalendarBlankOutline, mdiClose, mdiEmail } from '@mdi/js'
  import Competitions from "../utils/Competitions";
  import Menu from "../components/register/Menu";
  export default {
    name: 'register',
    components: {
      Menu,
    },
    mixins: [
      Competitions,
    ],
    data () {
      return {
        snackbar: {
          value: false,
          text: '',
          color: 'success',
          timeout: 10000,
          bottom: true,
        },
        icons: {
          calendar: mdiCalendarBlankOutline,
          close: mdiClose,
          mail: mdiEmail,
        },
        validate: false,
        today: this.$moment().format('YYYY-MM-DD'),
        register: {
          valid: null,
          firstName: null,
          lastName: null,
          sex: null,
          birthYear: null,
          email: null,
          menu: false,
          nationality: null,
          shirtSize: null,
          association: null,
          rules: false,
          message: null,
          promotionCode: null,
          misc: null,
          competition: null
        },
        language: this.$i18n.locale,
        stripe: {
          init: undefined,
        },
        dialog: {
          response: false,
          registering: false,
        },
        success: false,
        loading: false,
      };
    },
    watch: {
      /* eslint-disable object-shorthand */
      'register.menu'(val) {
        /* eslint-disable no-unused-expressions */
        /* eslint-disable no-return-assign */
        val && this.$nextTick(() => (
          this.$refs.picker.activePicker = 'YEAR'
        ));
      },
    },
    created() {
      if (this.$route.query.competition !== undefined) {
        for (let i = 0; i < this.competitions.length; i += 1) {
          if (this.competitions[i].value === this.$route.query.competition && !this.competitions[i].disabled) {
            this.register.competition = this.competitions[i];
          }
        }
      }
      if (this.$route.query.success !== undefined) {
        this.success = this.$route.query.success === 'true';
        this.dialog.response = true;
      }
      this.$router.replace({});
    },
    mounted() {
      this.stripe.init = Stripe('pk_live_XYBYmtLKllz5NR5BfoNVeSbR00gtSDQ0fa');
    },
    methods: {
      isEnglish() {
        return this.getLanguage() === 'EN';
      },
      isItalian() {
        return this.getLanguage() === 'IT';
      },
      selectRules() {
        this.register.rules = !this.register.rules;
      },
      getLanguage() {
        return this.$store.getters.getLanguage;
      },
      downloadRules() {
        if (this.getLanguage() === 'IT') {
          window.open('https://www.ebikecup.com/rules/Reglement_it.pdf', '_blank');
        } else if (this.getLanguage() === 'EN') {
          window.open('https://www.ebikecup.com/rules/Reglement_en.pdf', '_blank');
        } else {
          window.open('https://www.ebikecup.com/rules/Reglement_de.pdf', '_blank');
        }
      },
      selectShirtSize(size) {
        this.register.shirtSize = size;
      },
      isShirtSize(size) {
        if (this.register.shirtSize === null) {
          return false;
        }
        return this.register.shirtSize === size;
      },
      selectSex(sex) {
        this.register.sex = sex;
      },
      isSexSelected(sex) {
        if (this.register.sex === null) {
          return false;
        }
        return this.register.sex === sex;
      },
      formatDate(date) {
        if (!date) return null;
        /* eslint-disable no-unused-vars */
        const [year, month, day] = date.split('-');
        return `${year}`;
      },
      checkIfSexIsSelected() {
        return this.register.sex === null && this.validate;
      },
      checkIfShirtSizeIsSelected() {
        return this.register.shirtSize === null && this.validate;
      },
      resetForm() {
        this.validate = false;
        this.$refs.form.reset();
        this.register.sex = null;
        this.register.shirtSize = null;
      },
      redirectToStripe(session) {
        this.stripe.init.redirectToCheckout({
          sessionId: session.id
        }).then((data) => {
          this.loading = false;
          if (data.error) {
            this.dialog.registering = true;
          }
        }, () => {
          this.loading = false;
          this.dialog.registering = true;
        });
      },
      submit(e) {
        e.preventDefault();
        this.validate = true;
        if (!this.$refs.form.validate() || this.checkIfSexIsSelected()) {
          this.snackbar.text = this.$t('register.alert.required');
          this.snackbar.color = 'error';
          this.snackbar.timeout = 10000;
          this.snackbar.value = true;
        } else if (this.isCompetitionDisabled(this.register.competition.value)) {
          this.snackbar.text = this.$t('register.alert.competitionDisabled');
          this.snackbar.color = 'error';
          this.snackbar.timeout = 10000;
          this.snackbar.value = true;
        } else {
          this.loading = true;
          const register = {
            firstName: this.register.firstName,
            lastName: this.register.lastName,
            selectedPackage: null,
            sex: this.register.sex,
            birthYear: this.register.birthYear,
            mail: this.register.email,
            nationality: this.register.nationality.value,
            rentBike: null,
            shirtSize: null,
            promotionCode: this.register.promotionCode === null
            || this.register.promotionCode === ''
              ? null : this.register.promotionCode,
            association: this.register.association === null
            || this.register.association === ''
              ? null : this.register.association,
            competition: this.register.competition.value,
            competitionYear: '2022',
            message: this.register.message === null
            || this.register.message === ''
              ? null : this.register.message,
            height: null,
            language: this.getLanguage(),
            misc: this.register.misc,
            startNumber: 0,
            transactionSrc: null
          };
          this.$http.post('competitors', register)
            .then((result) => {
              const response = result.body;
              if (response.statusCode === 400) {
                this.loading = false;
                this.snackbar.text = this.$t('register.alert.error');
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (response.statusCode === 999) {
                // NOT VALID
                this.snackbar.text = this.$t('register.alert.promotionCode.notValid');
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (response.statusCode === 998) {
                // NO FEE
                this.success = true;
                this.dialog.response = true;
                this.loading = false;
                this.resetForm();
              } else if (response.statusCode === 997) {
                // RAN OUT
                this.snackbar.text = this.$t('register.alert.promotionCode.ranOut');
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (response.statusCode === 996) {
                // MAIL ALREADY USED
                this.snackbar.text = this.$t('register.alert.used_mail');
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else {
                this.redirectToStripe(response.session);
              }
            }, (data) => {
              this.loading = false;
              this.snackbar.text = this.$t('register.alert.error');
              this.snackbar.color = 'error';
              this.snackbar.timeout = 10000;
              this.snackbar.value = true;
            });
        }
      },
    },
    computed: {
      computedDateFormatted: {
        get () {
          return this.formatDate(this.register.birthday);
        },
        set () {
        }
      },
      countries() {
        return [
          { label: this.$t('register.country.AT'), value: 'AT' },
          { label: this.$t('register.country.DE'), value: 'DE' },
          { label: this.$t('register.country.IT'), value: 'IT' },
          { label: this.$t('register.country.CH'), value: 'CH' },
          { label: this.$t('register.country.OTHER'), value: 'OTHER' },
        ];
      },
      rules() {
        return [val => (val || '').length > 0 || this.$t('required')];
      },
      emailRules() {
        return [
          v => !!v || this.$t('required'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailNotValid'),
        ];
      },
      yearRules () {
        return [
          val => (val || '').length > 0 || this.$t('required'),
          val => (val !== null && this.$moment().subtract(9, 'years').year() >= Number(val))
            || this.$t('checkYourAge'),
          val => (val !== null && this.$moment().subtract(100, 'years').year() < Number(val))
            || this.$t('checkYourAge'),
          val => (val !== null && val.length === 4) || this.$t('yearFormatIncorrect')
        ];
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";

  .register-dialog-container {
    h1 {
      font-weight: 500;
      line-height: 1em;
      color: $primary-text;
    }
    img {
      width: 100%;
      max-width: 450px;
      @include MQ(XS) {
        max-width: 250px;
      }
      @include MQ(S) {
        max-width: 325px;
      }
    }
  }
  .register-container {
    font-family: $default-font;

    .header {
      position: relative;
      height: 500px;
      .header-background {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 500px;
        position: fixed;
        z-index: 0;

        .header-title-container {
          position: absolute;
          bottom: 50px;
          left: 200px;
          text-shadow: 2px 2px 4px #000000;
          @include MQ(XS) {
            left: 10px;
            bottom: 25px;
          }
          @include MQ(S) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(M) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(L) {
            left: 150px;
            bottom: 35px;
          }
          h1 {
            font-style: italic;
            color: white;
            font-size: 72px;
            line-height: 72px;
            text-align: left;
            span {
              color: $primary;
            }
            @include MQ(XS) {
              font-size: 38px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            @include MQ(S) {
              font-size: 48px;
              line-height: 48px;
              margin-bottom: 10px;
            }
            @include MQ(M) {
              font-size: 48px;
              line-height: 48px;
            }
            @include MQ(L) {
              font-size: 62px;
              line-height: 62px;
            }
          }
        }
      }
    }

    .register-content-wrapper {
      position: relative;
      background-color: white;
      padding-top: 45px;
      padding-bottom: 20px;

      .deal-content {
        text-align: left;
        font-size: 18px;
        color: $primary-text;
        font-family: $default-font;
        width: 100%;
        ul {
          margin-left: 10px;
          list-style: none;
          @include MQ(XS) {
            margin-left: 0;
          }
          li {
            word-break: keep-all;
            &:before {
              content: "»";
              color: $secondary;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
              margin-top: -5px;
            }
          }
        }
        p {
          color: $primary-text;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .rent-partner {
          max-width: 250px;
          @include MQ(XS) {
            width: 100%;
            max-width: 100%;
          }
        }

        .headline {
          color: $primary-text;
          font-family: $default-font;
        }

        .form-required-title {
          color: $primary;
        }

        .v-btn {
          margin-left: 0;
          margin-right: 25px;
          text-transform: none;
          border-radius: 10px;
          margin-bottom: 10px;
          &.selected {
            background-color: $primary;
            color: white;
          }
          &.error {
            background-color: $basic-red;
            color: white;
          }
          .v-btn__content {
            font-size: 18px;
          }
        }

        .birthday {
          margin-top: 10px;
        }

        .v-input {
          margin-bottom: 10px;
          .v-text-field__slot {
            .v-label {
              font-size: 20px;
              height: 22px;
            }
            input {
              font-size: 18px;
              max-height: 40px;
            }
          }
        }

        .v-select {
          &.error--text {
            .v-select__slot {
              .v-label {
                color: #F53A33;
              }
            }
          }
        }

        .misc {
          position: absolute;
          opacity: 0;
        }
      }
    }
  }

  .v-menu__content {
    .v-picker {
      .v-picker__body {
        div {
          .v-date-picker-header {
            .v-date-picker-header__value {
              height: 37px;
              button {
                background-color: $primary;
                color: white !important;
                border-radius: 10px;
                box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),
                0 2px 2px 0 rgba(0,0,0,0.14),
                0 1px 5px 0 rgba(0,0,0,0.12);
              }
            }
          }
        }
      }
    }
  }
</style>
